import type { LoaderArgs, V2_MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ThsSession } from "./session/session.class.server";
import draftjs from "draft-js/dist/Draft.css";
import tailwind from "~/assets/precompile.css";
import favicon from "~/assets/favicons/favicon.ico";
import appleTouchIcon from "~/assets/favicons/apple-touch-icon.png";
import favicon16x16 from "~/assets/favicons/favicon-16x16.png";
import favicon32x32 from "~/assets/favicons/favicon-32x32.png";
import { getAnimalTypes } from "./general-domain-functions/animal-type.query.server";
import { useHydrateAtoms } from "jotai/utils";
import {
  globalAnimalTypesAtom,
  globalEnrichmentTypesAtom,
  globalHumanTypesAtom } from
"./components/global-state/atoms";
import { getHumanTypes } from "./general-domain-functions/human-type.query.server";
import sadDogArt from "~/assets/error-dog.jpg";
import type { InputError } from "domain-functions";
import { getEnrichmentTypes } from "./general-domain-functions/enrichment-type.query.server";
import { mapKeys } from "lodash";

import { withSentry } from "@sentry/remix";

export const meta: V2_MetaFunction = () => [
{
  charset: "utf-8"
},
{
  title: "THS - Walklist"
},
{
  viewport: "width=device-width,initial-scale=1"
}];


export async function loader(args: LoaderArgs) {
  const session = await ThsSession.getFromLoaderArgs(args);
  const successToast: string | undefined = session.session.get("success-toast");
  const animalTypes = await getAnimalTypes({});
  const humanTypes = await getHumanTypes({});
  const enrichmentTypes = await getEnrichmentTypes({});

  if (animalTypes.success === false) throw json(animalTypes);
  if (humanTypes.success === false) throw json(humanTypes);
  if (enrichmentTypes.success === false) throw json(enrichmentTypes);

  return json(
    {
      url: args.request.url,
      successToast,
      animalTypes: animalTypes.data,
      humanTypes: humanTypes.data,
      enrichmentTypes: enrichmentTypes.data
    },
    {
      headers: {
        "Set-Cookie": await session.commit()
      }
    }
  );
}

export const handle = {
  isRoot: true
};

export function links() {
  return [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: appleTouchIcon
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: favicon32x32
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: favicon16x16
  },
  {
    rel: "icon",
    type: "image/png",
    href: favicon
  },
  {
    rel: "stylesheet",
    href: tailwind
  },
  { rel: "stylesheet", href: draftjs }];

}

function App() {
  const {
    successToast,
    animalTypes: animalTypeResult,
    humanTypes: humanTypeResult,
    enrichmentTypes: enrichmentTypeResult
  } = useLoaderData<typeof loader>();
  useHydrateAtoms([
  [globalAnimalTypesAtom, animalTypeResult],
  [globalHumanTypesAtom, humanTypeResult],
  [globalEnrichmentTypesAtom, enrichmentTypeResult]]
  );
  useGlobalSuccessToast(successToast);

  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        <Links />
        <noscript>
          <style
            dangerouslySetInnerHTML={{
              __html: `
              .ssr-animation-friendly {
                opacity: 1 !important;
              }
            `
            }} />

        </noscript>
      </head>
      <body className="h-full">
        <Outlet />
        <Toaster position="top-right" reverseOrder={false} />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

const AppWithSentry = withSentry(App);
export default AppWithSentry;

function useGlobalSuccessToast(successToast: string | undefined) {
  useEffect(() => {
    if (successToast) {
      toast.success(successToast, { duration: 5000 });
    }
  }, [successToast]);
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.error({ error });
  let errorMessage =
  error instanceof Error ?
  error.message :
  "We encountered an unexpected issue.";

  if (
  (error as any)?.data?.inputErrors &&
  (error as any)?.data?.inputErrors.length > 0)
  {
    const err = ((error as any)?.data?.inputErrors[0] as InputError);
    errorMessage = `${err.name || err.path}: ${err.message}`;
  }

  if (
  (error as any)?.data?.environmentErrors &&
  (error as any)?.data?.environmentErrors.length > 0)
  {
    errorMessage = (error as any)?.data?.environmentErrors[0].message;
  }

  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        <Links />
        <noscript>
          <style
            dangerouslySetInnerHTML={{
              __html: `
              .ssr-animation-friendly {
                opacity: 1 !important;
              }
            `
            }} />

        </noscript>
      </head>
      <body className="h-full">
        <div className="flex min-h-screen items-center justify-center bg-primary-50 px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8 rounded bg-white p-8 shadow shadow-slate-700">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-900">
              {error instanceof Error ?
              "Oops! An error occurred" :
              "Something went wrong"}
              ({(error as any).status ? (error as any).status : "Unknown Error"}
              )
            </h2>
            <img src={sadDogArt} alt="a sad dog" />
            <p className="mt-2 text-center text-sm text-primary-600">
              Error Message: {errorMessage}
            </p>
            <Link
              to="/"
              className="mt-4 block w-full rounded-md border border-transparent bg-primary-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">

              Go back
            </Link>
          </div>
        </div>
      </body>
    </html>);

};